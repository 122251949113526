import { useUpdateEffect } from '@/hook'
import cn from 'classnames'
import React, {
  forwardRef, useEffect, useImperativeHandle, useRef, useState
} from 'react'

import PropTypes from 'prop-types'

import { Transition } from 'react-transition-group'

import Icon from '@/components/Icon'
import {
  Container, DropDownItem, DropDownItemIcon, DropDownItemLabel, DropDownLabel, DropDownList, DropDownSelect, DropDownSelectItem, DropDownWrapper, IconWrapper, Placeholder, TextWrapper, Wrapper
} from './style'

const Select = forwardRef(
  (
    {
      label = '',
      placeholder = '',
      list = [],
      isRequired = false,
      onChange = null,
      validator = null,
      defaultValue = '',
      name = '',
      nameSet = '',
      withDropDownIcon = false,
      isFilter = false,
      withQuickSearch = false,
      isSlideSelector = false,
      separation
    },
    ref
  ) => {
    // LOCAL VALUE
    const [visible, setVisible] = useState(false)
    const containerRef = useRef(null)
    const selectRef = useRef(null)

    const [value, setValue] = useState(defaultValue)
    const [error, setError] = useState(false)
    const [search, setSearch] = useState('')
    const [filteredList, setFilteredList] = useState(list)

    // ON COMPONENT UPDATE
    useUpdateEffect(() => {
      if (onChange) {
        onChange(value, list.indexOf(value))
      }
    }, [value])

    useEffect(() => {
      setFilteredList(list)
    }, [])

    useEffect(() => {
      setFilteredList(list)
    }, [list])

    useEffect(() => {
      setValue(defaultValue)
    }, [defaultValue])

    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside (event) {
        if (
          containerRef.current &&
          !containerRef.current.contains(event.target)
        ) {
          if (visible) {
            setVisible(!visible)
          }
        }
      }
      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [visible])

    // PRIVATE METHODS

    const handleToggle = () => {
      setVisible(!visible)
      setError(false)
      if (!visible) {
        setSearch('')
      }
    }

    const handleChange = (newVal) => {
      setValue(newVal)
      setVisible(false)

      if (selectRef.current) {
        selectRef.current.value = newVal
      }
    }

    const checkStatus = () => {
      let err = false

      if (isRequired) {
        if (!value.length) {
          err = true
        }
        if (value.length && validator) {
          err = validator(value)
        }
      }

      setError(err)

      return err
    }

    // PUBLIC METHODS
    useImperativeHandle(ref, () => ({
      isRequired: () => isRequired,
      getName: () => name,
      getValue: () => value,
      hasError: () => error,
      triggerError: (e) => setError(e),
      isValid: () => !checkStatus()
    }))

    const handleKeys = ({ key }) => {
      if (!withQuickSearch) return
      setSearch((old) => old + key)
    }

    useEffect(() => {
      window.addEventListener('keypress', handleKeys)
      return () => {
        window.removeEventListener('keypress', handleKeys)
      }
    }, [])

    useEffect(() => {
      if (!search) {
        setFilteredList(list)
      } else {
        setFilteredList(
          list.filter((v) => v.toLowerCase().startsWith(search.toLowerCase()))
        )
      }
    }, [search])

    return (
      <Container
        ref={ containerRef }
        className={ `${isFilter ? 'withoutStyle' : ''} ${
          separation ? 'separation' : ''
        } ${isSlideSelector ? 'isSlideSelector' : ''}` }
      >
        <Wrapper
          tabIndex={ 0 }
          error={ error }
          className={ cn({ focus: visible }) }
          onClick={ () => handleToggle() }
          onKeyDown={ (e) => {
            if (e.key === 'Enter') {
              handleToggle()
              e.preventDefault()
            }
          } }
        >
          {placeholder
            ? (
              <TextWrapper>
                {label && <DropDownLabel htmlFor={ label }>{label}</DropDownLabel>}
                {placeholder && (
                  <Placeholder>
                    { value || placeholder }
                  </Placeholder>
                )}
              </TextWrapper>
              )
            : (
              <DropDownLabel htmlFor={ label } state={ value.length > 0 }>
                {value.length ? value : label}
              </DropDownLabel>
              )}

          {withDropDownIcon && (
            <IconWrapper state={ visible }>
              <Icon name='arrow-bottom' size={ isFilter ? 8 : 15 } />
            </IconWrapper>
          )}
        </Wrapper>
        <DropDownSelect id={ label } title={ label } name={ label } ref={ selectRef }>
          <optgroup label={ nameSet }>
            {filteredList.map((item, key) => {
              const v = typeof item === 'object' ? item.value : item

              return (
                <option
                  key={ key }
                  selected={ value === v }
                  value={ v }
                >
                  {v}
                </option>
              )
            })}
          </optgroup>
        </DropDownSelect>
        <Transition in={ visible } timeout={ 250 } unmountOnExit mountOnEnter>
          {(state) => (
            <DropDownWrapper state={ state }>
              <DropDownList>
                {filteredList.map((item, key) => {
                  const v = typeof item === 'object' ? item.value : item

                  return (
                    <DropDownItem
                      className={ `${value === v && 'active'}` }
                      key={ key }
                      onClick={ () => handleChange(v) }
                      onKeyDown={ (e) => {
                        if (e.key === 'Enter') {
                          handleChange(v)
                        }
                      } }
                    >
                      <DropDownItemLabel
                        active={ value === v }
                        role='button'
                        tabIndex={ 0 }
                      >
                        {v}{' '}
                        {item.icon && (
                          <DropDownItemIcon>
                            <Icon name={ item.icon } size={ 12 } />
                          </DropDownItemIcon>
                        )}
                      </DropDownItemLabel>
                    </DropDownItem>
                  )
                })}
              </DropDownList>
            </DropDownWrapper>
          )}
        </Transition>
      </Container>
    )
  }
)

Select.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  list: PropTypes.array,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  validator: PropTypes.func,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  nameSet: PropTypes.string,
  withDropDownIcon: PropTypes.bool,
  isFilter: PropTypes.bool
}

export default Select
